import React, { useState, useEffect, useRef } from 'react';
import { db } from '../../firebase/firebase-config';
import { collection, query, orderBy, onSnapshot, doc, getDocs, deleteDoc, where, getDoc, limit, startAfter } from 'firebase/firestore';
import { ListGroup, Badge, Button, Row, Col } from 'react-bootstrap';
import { FaEdit, FaTrash } from 'react-icons/fa'; // Import edit and trash icons
import './NoticeList.css';
import { useUser } from '../../context/UserContext';

const NOTICES_LIMIT = 20;

function NoticeList({ onSelectNotice, isAdmin, onEditNotice }) { // Accept onEditNotice prop
  const [notices, setNotices] = useState([]);
  const [lastVisible, setLastVisible] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const noticesEndRef = useRef(null);
  const noticesContainerRef = useRef(null);
  const [unreadNotices, setUnreadNotices] = useState([]);
  const [unitNames, setUnitNames] = useState({});

  const { user } = useUser(); // Use user context

  useEffect(() => {
    const fetchUnits = async () => {
      const unitsQuerySnapshot = await getDocs(collection(db, 'units'));
      const unitsData = unitsQuerySnapshot.docs.reduce((acc, unitDoc) => {
        acc[unitDoc.id] = unitDoc.data().unitName;
        return acc;
      }, {});
      setUnitNames(unitsData);
    };

    fetchUnits();
  }, []);

  useEffect(() => {
    const fetchUnreadNotices = async () => {
      const userDocRef = doc(db, 'users', user.uid);
      const userDocSnap = await getDoc(userDocRef);
      const lastReadTimestamps = userDocSnap.data().lastRead?.notices || {};

      const newUnreadNotices = [];
      let q;

      // Admins can see all notices
      if (user.role === 'admin') {
        q = query(collection(db, 'notices'), orderBy('createdAt', 'desc'), limit(NOTICES_LIMIT));
      }
      // Local-admins and users see notices from their unit and the general unit
      else {
        q = query(
          collection(db, 'notices'),
          where('unit', 'in', ['general', user.unit]),
          orderBy('createdAt', 'desc'),
          limit(NOTICES_LIMIT)
        );
      }

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        const noticesData = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
          createdAt: doc.data().createdAt.toMillis()
        }));

        setNotices(noticesData);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);

        querySnapshot.forEach((doc) => {
          const noticeId = doc.id;
          const createdAt = doc.data().createdAt;
          if (!lastReadTimestamps[noticeId] || lastReadTimestamps[noticeId].toMillis() < createdAt.toMillis()) {
            newUnreadNotices.push(noticeId);
          }
        });
        setUnreadNotices(newUnreadNotices);
      });

      return () => unsubscribe();
    };

    fetchUnreadNotices();
  }, [user]);

  const loadMoreNotices = async () => {
    if (!lastVisible || loadingMore) return;

    setLoadingMore(true);
    const q = query(
      collection(db, 'notices'),
      orderBy('createdAt', 'desc'),
      startAfter(lastVisible),
      limit(NOTICES_LIMIT)
    );
    const querySnapshot = await getDocs(q);
    const moreNotices = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      createdAt: doc.data().createdAt.toMillis()
    }));

    setNotices(prevNotices => [...prevNotices, ...moreNotices]);
    setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
    setLoadingMore(false);
  };

  const handleScroll = () => {
    if (noticesContainerRef.current.scrollTop + noticesContainerRef.current.clientHeight >= noticesContainerRef.current.scrollHeight) {
      loadMoreNotices();
    }
  };

  const handleDelete = async (noticeId) => {
    if (window.confirm('Are you sure you want to delete this notice?')) {
      await deleteDoc(doc(db, 'notices', noticeId));
    }
  };

  return (
    <div className="notice-list-container" ref={noticesContainerRef} onScroll={handleScroll}>
      <ListGroup className="notice-list">
        {notices.map((notice) => (
          <ListGroup.Item key={notice.id} className="notice-list-item">
            <Row>
              <Col md={12} onClick={() => onSelectNotice(notice)} className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{notice.title}</div>
                  <small>Ustvaril {notice.displayName || 'Anonymous'}</small>
                </div>
                <Badge bg="info" className="ms-2">{notice.unit === 'general' ? 'Splošno' : notice.unitName}</Badge>
                <small className="text-muted ms-2">{new Date(notice.createdAt).toLocaleString()}</small>
                {unreadNotices.includes(notice.id) && (
                  <Badge bg="danger" className="ms-2" pill>&#8226;</Badge>
                )}
              </Col>
            </Row>
            {isAdmin && (
              <Row>
                <Col md={12} className="d-flex ms-2 my-2">
                  <Button variant="outline-primary" size="sm" onClick={() => onEditNotice(notice)} className="me-2">
                    <FaEdit /> Uredi
                  </Button>
                  <Button variant="outline-danger" size="sm" onClick={() => handleDelete(notice.id)}>
                    <FaTrash /> Izbriši
                  </Button>
                </Col>
              </Row>
            )}
          </ListGroup.Item>
        ))}
        <div ref={noticesEndRef} />
      </ListGroup>
    </div>
  );
}

export default NoticeList;
