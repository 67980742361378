import React, { useState, useEffect } from 'react';
import { db, storage, auth } from '../../firebase/firebase-config';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { Button, Form, Container, Row, Col, Alert, ProgressBar } from 'react-bootstrap';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import './BenefitCreate.css';
import { useUser } from '../../context/UserContext';

const modules = {
    toolbar: [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ size: [] }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['link'],
        ['clean']
    ],
};

function BenefitCreate({ onBenefitCreated }) {
    const [title, setTitle] = useState('');
    const [text, setText] = useState('');
    const [unit, setUnit] = useState('general');
    const [unitName, setUnitName] = useState('Splošno');
    const [units, setUnits] = useState([]);
    const [file, setFile] = useState(null);
    const [fileUrl, setFileUrl] = useState('');
    const [error, setError] = useState('');
    const [uploading, setUploading] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);

    const { user } = useUser();

    useEffect(() => {
        const fetchUnits = async () => {
            const unitsCollectionRef = collection(db, 'units');
            const unitsSnapshot = await getDocs(unitsCollectionRef);
            const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
            setUnits(unitsList);

            if (user.role === 'local-admin') {
                const userUnit = unitsList.find(unit => unit.id === user.unit);
                if (userUnit) {
                    setUnit(user.unit);
                    setUnitName(userUnit.unitName);
                }
            }
        };

        fetchUnits();
    }, [user]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!auth.currentUser) {
            setError("You must be logged in to create a benefit.");
            return;
        }
        if (!title.trim() || !text.trim()) {
            setError("Title and text cannot be empty.");
            return;
        }

        let fileDownloadUrl = fileUrl;

        if (file) {
            setUploading(true);
            try {
                const storageRef = ref(storage, `benefits/${file.name}`);
                const uploadTask = uploadBytesResumable(storageRef, file);

                uploadTask.on(
                    'state_changed',
                    (snapshot) => {
                        const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                        setUploadProgress(progress);
                    },
                    (error) => {
                        console.error('Upload error:', error);
                        setError('Failed to upload file. Please try again.');
                        setUploading(false);
                    },
                    async () => {
                        fileDownloadUrl = await getDownloadURL(uploadTask.snapshot.ref);
                        saveBenefit(fileDownloadUrl);
                        setUploading(false);
                    }
                );
            } catch (error) {
                setError('Failed to upload file. Please try again.');
                console.error('Error uploading file:', error);
                setUploading(false);
                return;
            }
        } else {
            saveBenefit(fileDownloadUrl);
        }
    };

    const saveBenefit = async (fileDownloadUrl) => {
        try {
            await addDoc(collection(db, 'benefits'), {
                title,
                text,
                unit: unit || 'general',
                unitName,
                createdAt: new Date(),
                uid: auth.currentUser.uid,
                displayName: user.displayName || 'Anonymous',
                fileUrl: fileDownloadUrl || '',
            });
            setTitle('');
            setText('');
            setUnit('general');
            setUnitName('Splošno');
            setFile(null);
            setFileUrl('');
            setError('');
            onBenefitCreated();
        } catch (error) {
            setError("Failed to create benefit. Please try again.");
            console.error("Error creating benefit:", error);
        }
    };

    return (
        <Container className="benefit-create-container">
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Naslov</Form.Label>
                            <Form.Control
                                type="text"
                                placeholder="Vpišite naslov"
                                value={title}
                                onChange={(e) => setTitle(e.target.value)}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Besedilo</Form.Label>
                            <div className="quill-container">
                                <ReactQuill
                                    value={text}
                                    onChange={setText}
                                    placeholder="Vpišite obvestilo"
                                    theme="snow"
                                    modules={modules}
                                />
                            </div>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Enota</Form.Label>
                            <Form.Control
                                as="select"
                                value={unit}
                                onChange={(e) => setUnit(e.target.value)}
                                disabled={user.role === 'local-admin'}
                            >
                                <option value="general">Splošno</option>
                                {units.map(unit => (
                                    <option key={unit.id} value={unit.id}>{unit.unitName}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Datoteka</Form.Label>
                            <Form.Control
                                type="file"
                                onChange={(e) => setFile(e.target.files[0])}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                {uploading && (
                    <ProgressBar now={uploadProgress} label={`${Math.round(uploadProgress)}%`} striped />
                )}
                <Button variant="danger" type="submit" disabled={uploading}>
                    Objavi ugodnost
                </Button>
            </Form>
        </Container>
    );
}

export default BenefitCreate;
