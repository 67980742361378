import React, { useEffect, useState } from 'react';
import { auth, db } from '../../firebase/firebase-config';
import { collection, query, onSnapshot, orderBy, getDoc, doc, deleteDoc, where } from 'firebase/firestore';
import { ListGroup, Badge, Button, Row, Col } from 'react-bootstrap';
import { FaTrash } from 'react-icons/fa';
import './QnAList.css';
import QnAQuestion from '../../models/QnAQuestion';
import { useUser } from '../../context/UserContext';

function QnAList({ onSelectQuestion }) {
  const [questions, setQuestions] = useState([]);
  const [unreadStatus, setUnreadStatus] = useState({});
  const { user, loading } = useUser(); // Access user and loading states

  useEffect(() => {
    if (loading || !user) return;

    let q;
    if (user.role === 'admin') {
      q = query(collection(db, "questions"), orderBy("createdAt", "desc"));
    } else {
      q = query(
        collection(db, "questions"),
        where('unit', 'in', ['general', user.unit]),
        orderBy("createdAt", "desc")
      );
    }

    const unsubscribe = onSnapshot(q, (querySnapshot) => {
      const questionsList = querySnapshot.docs.map(doc => QnAQuestion.fromFirestore(doc));
      setQuestions(questionsList);
    });

    return () => unsubscribe();
  }, [user, loading]);

  useEffect(() => {
    if (loading || !user) return;

    const checkUnreadStatus = async () => {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      const lastReadTimestamps = userDocSnap.data().lastRead?.qna || {};

      const newUnreadStatus = {};
      const q = query(collection(db, 'questions'));

      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const questionId = doc.id;
          const createdAt = doc.data().createdAt.toMillis();
          const lastReadTimestamp = lastReadTimestamps[questionId]?.toMillis() || 0;

          newUnreadStatus[questionId] = createdAt > lastReadTimestamp;
        });
        setUnreadStatus(newUnreadStatus);
      });

      return () => unsubscribe();
    };

    checkUnreadStatus();
  }, [loading, user]);

  const handleDelete = async (questionId) => {
    if (window.confirm('Are you sure you want to delete this question?')) {
      await deleteDoc(doc(db, 'questions', questionId));
    }
  };

  if (loading) {
    return <div>Loading...</div>;
  }

  if (!questions.length) {
    return <div>No questions available</div>;
  }

  return (
    <div className="qna-list-container">
      <ListGroup className="qna-list">
        {questions.map((question) => (
          <ListGroup.Item key={question.id} className="qna-list-item">
            <Row>
              <Col md={12} onClick={() => onSelectQuestion(question)} className="d-flex justify-content-between align-items-start">
                <div className="ms-2 me-auto">
                  <div className="fw-bold">{question.title}</div>
                  <small>Ustvaril {question.displayName}</small>
                </div>
                <Badge bg="info" className="ms-2">{question.unit === 'general' ? 'Splošno' : question.unitName}</Badge>
                <small className="text-muted text-end ms-2">{question.createdAt.toLocaleString()}</small>
                {unreadStatus[question.id] && (
                  <Badge bg="danger" className="ms-2" pill>&#8226;</Badge>
                )}
              </Col>
            </Row>
            {user.role === 'admin' && (
              <Row>
                <Col md={2} className="d-flex ms-2 my-2">
                  <Button variant="outline-danger" size="sm" onClick={() => handleDelete(question.id)}>
                    <FaTrash /> Izbriši
                  </Button>
                </Col>
              </Row>
            )}
          </ListGroup.Item>
        ))}
      </ListGroup>
    </div>
  );
}

export default QnAList;
