import React, { useState, useEffect } from 'react';
import { db } from '../../firebase/firebase-config';
import { collection, addDoc, getDocs } from 'firebase/firestore';
import { Button, Form, FormControl, Alert, Container, Row, Col } from 'react-bootstrap';
import { useUser } from '../../context/UserContext';
import QnAQuestion from '../../models/QnAQuestion';  // Import the model
import './QnACreateQuestion.css';

function QnACreateQuestion({ onQuestionCreated }) {
    const [question, setQuestion] = useState('');
    const [error, setError] = useState('');
    const [unit, setUnit] = useState('general'); // Default to 'general'
    const [unitName, setUnitName] = useState('Splošno'); // Default to 'Splošno' for general
    const [units, setUnits] = useState([]); // To store units fetched from Firestore
    const { user } = useUser();  // Use context to access user info

    // Fetch the units from Firestore on component mount
    useEffect(() => {
        const fetchUnits = async () => {
            const unitsCollectionRef = collection(db, 'units');
            const unitsSnapshot = await getDocs(unitsCollectionRef);
            const unitsList = unitsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));

            // If user is not an admin, filter units to show only the user's own unit and general
            if (user.role !== 'admin') {
                const filteredUnits = unitsList.filter(unit => unit.id === user.unit);
                setUnits([{ id: 'general', unitName: 'Splošno' }, ...filteredUnits]);
            } else {
                // Admin can select any unit
                setUnits([{ id: 'general', unitName: 'Splošno' }, ...unitsList]);
            }
        };

        fetchUnits();
    }, [user]);

    // Update unit name when user selects a unit
    const handleUnitChange = (e) => {
        const selectedUnitId = e.target.value;
        const selectedUnit = units.find(unit => unit.id === selectedUnitId);
        setUnit(selectedUnitId);
        setUnitName(selectedUnit ? selectedUnit.unitName : 'Splošno');
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!user) {
            setError("You must be logged in to post a question.");
            return;
        }
        if (!question.trim()) {
            setError("The question cannot be empty.");
            return;
        }

        try {
            // Create a new QnAQuestion instance
            const newQuestion = new QnAQuestion({
                title: question,
                uid: user.uid,
                displayName: user.displayName,
                unit: unit,  // Attach the selected unit ID
                unitName: unitName,  // Attach the selected unit name
                createdAt: new Date()
            });

            // Add to Firestore using model's toFirestore method
            await addDoc(collection(db, "questions"), newQuestion.toFirestore());
            setQuestion('');
            setError('');
            setUnit('general');  // Reset to general unit
            setUnitName('Splošno');  // Reset to general unit name
            onQuestionCreated();  // Notify parent about the new question
        } catch (err) {
            setError("Failed to create the question. Please try again.");
            console.error("Error creating question:", err);
        }
    };

    return (
        <Container className="create-question-container">
            {error && <Alert variant="danger">{error}</Alert>}
            <Form onSubmit={handleSubmit}>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Vprašanje</Form.Label>
                            <FormControl
                                type="text"
                                placeholder="Vnesi vprašanje"
                                value={question}
                                onChange={(e) => setQuestion(e.target.value)}
                                isInvalid={!!error}
                            />
                            <Form.Control.Feedback type="invalid">
                                {error}
                            </Form.Control.Feedback>
                        </Form.Group>
                    </Col>
                </Row>
                <Row className="mb-3">
                    <Col>
                        <Form.Group>
                            <Form.Label>Enota</Form.Label>
                            <Form.Control
                                as="select"
                                value={unit}
                                onChange={handleUnitChange}
                            >
                                {units.map(unit => (
                                    <option key={unit.id} value={unit.id}>{unit.unitName}</option>
                                ))}
                            </Form.Control>
                        </Form.Group>
                    </Col>
                </Row>
                <Button variant="primary" type="submit" className="w-100">
                    Pošlji
                </Button>
            </Form>
        </Container>
    );
}

export default QnACreateQuestion;
