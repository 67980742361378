import React, { useState, useEffect, useRef } from 'react';
import { db, auth } from '../../firebase/firebase-config';
import { collection, query, orderBy, onSnapshot, doc, getDoc, limit, startAfter, getDocs, updateDoc, deleteDoc } from 'firebase/firestore';
import { ListGroup, Badge, Button, Row, Col, Modal, Form } from 'react-bootstrap';
import { FaTrash, FaSort } from 'react-icons/fa';
import './BenefitList.css';
import { useUser } from '../../context/UserContext';

const BENEFITS_LIMIT = 20;

function BenefitList({ onSelectBenefit, isAdmin, isLocalAdmin }) {
    const [benefits, setBenefits] = useState([]);
    const [lastVisible, setLastVisible] = useState(null);
    const [loadingMore, setLoadingMore] = useState(false);
    const benefitsEndRef = useRef(null);
    const benefitsContainerRef = useRef(null);
    const [unreadBenefits, setUnreadBenefits] = useState([]);
    const [unitNames, setUnitNames] = useState({});
    const { user } = useUser();
    const [showReorderModal, setShowReorderModal] = useState(false);
    const [selectedBenefit, setSelectedBenefit] = useState(null);
    const [movePosition, setMovePosition] = useState('');

    useEffect(() => {
        const fetchUnits = async () => {
            const unitsQuerySnapshot = await getDocs(collection(db, 'units'));
            const unitsData = unitsQuerySnapshot.docs.reduce((acc, unitDoc) => {
                acc[unitDoc.id] = unitDoc.data().unitName;
                return acc;
            }, {});
            setUnitNames(unitsData);
        };

        fetchUnits();
    }, []);

    useEffect(() => {
        const fetchUnreadBenefits = async () => {
            const userDocRef = doc(db, 'users', auth.currentUser.uid);
            const userDocSnap = await getDoc(userDocRef);
            const lastReadTimestamps = userDocSnap.data().lastRead?.benefits || {};

            const newUnreadBenefits = [];
            const q = query(collection(db, 'benefits'), orderBy('createdAt', 'desc'));

            const unsubscribe = onSnapshot(q, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    const benefitId = doc.id;
                    const createdAt = doc.data().createdAt;
                    if (!lastReadTimestamps[benefitId] || lastReadTimestamps[benefitId].toMillis() < createdAt.toMillis()) {
                        newUnreadBenefits.push(benefitId);
                    }
                });
                setUnreadBenefits(newUnreadBenefits);
            });

            return () => unsubscribe();
        };

        fetchUnreadBenefits();
    }, []);

    useEffect(() => {
        const q = query(collection(db, "benefits"), orderBy("order", "asc"), limit(BENEFITS_LIMIT));
        const unsubscribe = onSnapshot(q, (querySnapshot) => {
            const benefitsData = querySnapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                createdAt: doc.data().createdAt.toMillis()
            }));
            setBenefits(benefitsData);
            setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        });

        return () => unsubscribe();
    }, []);

    const loadMoreBenefits = async () => {
        if (!lastVisible || loadingMore) return;

        setLoadingMore(true);
        const q = query(collection(db, "benefits"), orderBy("order", "asc"), startAfter(lastVisible), limit(BENEFITS_LIMIT));
        const querySnapshot = await getDocs(q);
        const moreBenefits = querySnapshot.docs.map(doc => ({
            id: doc.id,
            ...doc.data(),
            createdAt: doc.data().createdAt.toMillis()
        }));

        setBenefits(prevBenefits => [...prevBenefits, ...moreBenefits]);
        setLastVisible(querySnapshot.docs[querySnapshot.docs.length - 1]);
        setLoadingMore(false);
    };

    const handleScroll = () => {
        if (benefitsContainerRef.current.scrollTop + benefitsContainerRef.current.clientHeight >= benefitsContainerRef.current.scrollHeight) {
            loadMoreBenefits();
        }
    };

    const handleDelete = async (benefitId) => {
        if (window.confirm('Are you sure you want to delete this benefit?')) {
            await deleteDoc(doc(db, 'benefits', benefitId));  // Delete the benefit from Firestore
        }
    };

    const handleReorderClick = (benefit) => {
        setSelectedBenefit(benefit);
        setShowReorderModal(true);
    };

    const handleMoveUp = async () => {
        const index = benefits.findIndex(b => b.id === selectedBenefit.id);
        if (index > 0) {
            const newOrder = benefits[index - 1].order;
            await updateDoc(doc(db, 'benefits', selectedBenefit.id), { order: newOrder - 1 });
        }
        setShowReorderModal(false);
    };

    const handleMoveDown = async () => {
        const index = benefits.findIndex(b => b.id === selectedBenefit.id);
        if (index < benefits.length - 1) {
            const newOrder = benefits[index + 1].order;
            await updateDoc(doc(db, 'benefits', selectedBenefit.id), { order: newOrder + 1 });
        }
        setShowReorderModal(false);
    };

    const handleMoveToPosition = async () => {
        const newPosition = parseInt(movePosition, 10);
        if (isNaN(newPosition) || newPosition < 1 || newPosition > benefits.length) {
            alert('Vnesite veljavno pozicijo.');
            return;
        }

        // Adjust the order to match the human-readable position
        const newOrder = benefits[newPosition - 1].order + 1; // Add 1 to shift the position properly
        await updateDoc(doc(db, 'benefits', selectedBenefit.id), { order: newOrder });
        setShowReorderModal(false);
    };


    return (
        <>
            <div className="benefit-list-container" ref={benefitsContainerRef} onScroll={handleScroll}>
                <ListGroup className="benefit-list">
                    {benefits.map((benefit) => (
                        <ListGroup.Item key={benefit.id} className="benefit-list-item">
                            <Row>
                                <Col md={12} onClick={() => onSelectBenefit(benefit)} className="d-flex justify-content-between align-items-start">
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{benefit.title}</div>
                                        <small>Ustvaril {benefit.displayName || 'Anonymous'}</small>
                                    </div>
                                    <Badge bg="info" className="ms-2">{benefit.unit === 'general' ? 'Splošno' : benefit.unitName}</Badge>
                                    <small className="text-muted">{new Date(benefit.createdAt).toLocaleString()}</small>
                                    {unreadBenefits.includes(benefit.id) && (
                                        <Badge bg="danger" className="ms-1" pill>&nbsp;</Badge>
                                    )}
                                </Col>
                            </Row>

                            {(isAdmin || isLocalAdmin) && (
                                <Row>
                                    <Col md={12} className="d-flex ms-2 my-2">
                                        {isAdmin && (
                                            <Button variant="outline-primary" size="sm" onClick={() => handleReorderClick(benefit)}>
                                                <FaSort /> Uredi vrstni red
                                            </Button>
                                        )}
                                        <Button
                                            variant="outline-danger"
                                            size="sm"
                                            className={isAdmin ? "ms-2" : ""} // Apply ms-2 only if isAdmin
                                            onClick={() => handleDelete(benefit.id)}
                                        >
                                            <FaTrash /> Izbriši
                                        </Button>
                                    </Col>
                                </Row>
                            )}

                        </ListGroup.Item>
                    ))}
                    <div ref={benefitsEndRef} />
                </ListGroup>
            </div>

            {/* Reorder Modal */}
            <Modal show={showReorderModal} onHide={() => setShowReorderModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>Uredi vrstni red</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Button variant="secondary" className="mb-2" onClick={handleMoveUp}>Premakni gor</Button>
                    <Button variant="secondary" className="mb-2" onClick={handleMoveDown}>Premakni dol</Button>
                    <Form>
                        <Form.Group className="mb-2">
                            <Form.Label>Premakni na pozicijo:</Form.Label>
                            <Form.Control
                                type="number"
                                min="1"
                                max={benefits.length}
                                value={movePosition}
                                onChange={(e) => setMovePosition(e.target.value)}
                            />
                        </Form.Group>
                        <Button variant="primary" onClick={handleMoveToPosition}>Potrdi premik</Button>
                    </Form>
                </Modal.Body>
            </Modal>
        </>
    );
}

export default BenefitList;
