import React, { useEffect } from 'react';
import { Card, Button } from 'react-bootstrap';
import { doc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { db, auth } from '../../firebase/firebase-config';
import './NoticeDetail.css';

function NoticeDetail({ notice }) {
  useEffect(() => {
    // Mark as read when the component is mounted
    const markAsRead = async () => {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      await updateDoc(userDocRef, {
        [`lastRead.notices.${notice.id}`]: serverTimestamp()
      });
    };

    markAsRead();
  }, [notice.id]);

  return (
    <Card className="d-flex flex-column notice-detail-card">
      <Card.Header as="h5" className="notice-detail-card-header">{notice.title}</Card.Header>
      <Card.Body className="flex-grow-1 notice-detail-card-body">
        {/* Render the saved rich text as HTML */}
        <div dangerouslySetInnerHTML={{ __html: notice.text }} />
        {/* Display the file button if a file is attached */}
        {notice.fileUrl && (
          <Button
            variant="primary"
            href={notice.fileUrl}
            target="_blank"
            rel="noopener noreferrer"
            className="mt-3"
          >
            Prenesi pripeto datoteko
          </Button>
        )}
      </Card.Body>
    </Card>
  );
}

export default NoticeDetail;
