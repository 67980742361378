import React, { useEffect, useState } from 'react';
import { ListGroup, Badge, Button, Row, Col } from 'react-bootstrap';
import { db, auth } from '../../firebase/firebase-config';
import { collection, query, onSnapshot, getDocs, updateDoc, doc, deleteDoc, where, orderBy } from 'firebase/firestore';
import { FaEdit, FaTrash, FaBan } from 'react-icons/fa';
import './PollsList.css';
import { useUser } from '../../context/UserContext';

function PollsList({ onSelectPoll, onEditPoll, isAdmin, onDeletePoll }) {
    const { user, loading } = useUser();
    const [polls, setPolls] = useState([]);
    const [unrespondedPolls, setUnrespondedPolls] = useState({});
    const [pollsWithNoAnswers, setPollsWithNoAnswers] = useState({});

    useEffect(() => {
        if (loading || !user) return;

        let q;

        // Admins can see all polls
        if (user.role === 'admin') {
            q = query(collection(db, "polls"), orderBy('createdAt', 'desc'));
        }
        // Local-admins and users see polls from their unit and the general unit
        else if (user.role === 'local-admin' || user.role === 'user') {
            q = query(
                collection(db, "polls"),
                where('unit', 'in', ['general', user.unit]),
                orderBy('createdAt', 'desc')
            );
        }

        const unsubscribe = onSnapshot(q, async (snapshot) => {
            const pollsData = snapshot.docs.map(doc => ({
                id: doc.id,
                ...doc.data(),
                createdAt: doc.data().createdAt?.toDate ? doc.data().createdAt.toDate().toLocaleString() : 'Ni datuma',
                expires: doc.data().expires?.toDate ? doc.data().expires.toDate().toLocaleString() : 'Ne poteče',
                unit: doc.data().unit || 'general',
                unitName: doc.data().unitName || 'Splošno'
            }));
            setPolls(pollsData);

            const newUnrespondedPolls = {};
            const newPollsWithNoAnswers = {};

            await Promise.all(pollsData.map(async (poll) => {
                const answersRef = collection(db, 'polls', poll.id, 'answers');
                const answersSnapshot = await getDocs(answersRef);

                // Check if the poll has any answers
                if (answersSnapshot.empty) {
                    newPollsWithNoAnswers[poll.id] = true; // No answers
                }

                const hasResponded = answersSnapshot.docs.some(answerDoc => answerDoc.data().userId === auth.currentUser.uid);
                if (!hasResponded) {
                    newUnrespondedPolls[poll.id] = true;
                }
            }));

            setUnrespondedPolls(newUnrespondedPolls);
            setPollsWithNoAnswers(newPollsWithNoAnswers); // Set polls that have no answers
        });

        return () => unsubscribe();
    }, [user, loading]);

    const handleDelete = async (pollId) => {
        if (window.confirm('Are you sure you want to delete this poll?')) {
            await onDeletePoll(pollId);
        }
    };

    const handleEndPoll = async (pollId) => {
        if (window.confirm('Are you sure you want to end this poll?')) {
            const pollRef = doc(db, 'polls', pollId);
            await updateDoc(pollRef, { active: false });
        }
    };

    if (loading) {
        return <div>Loading...</div>;
    }

    if (!polls.length) {
        return <div>No polls available</div>;
    }

    return (
        <div className="polls-list-container">
            <ListGroup className="polls-list">
                {polls.map(poll => (
                    <ListGroup.Item key={poll.id} className={`polls-list-item ${!poll.active ? 'inactive-poll' : ''}`} onClick={() => poll.active || isAdmin ? onSelectPoll(poll) : null}>
                        <Row>
                            <Col md={12} className={`d-flex justify-content-between align-items-start ${!poll.active ? 'inactive-poll-text' : ''}`}>
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold">{poll.title}</div>
                                    <small>Ustvaril {poll.displayName || 'Anonymous'}</small>
                                    <div><small>Poteče: {poll.expires}</small></div>
                                </div>

                                {/* Unit Badge */}
                                <Badge bg="info" className="ms-2">{poll.unit === 'general' ? 'Splošno' : poll.unitName}</Badge>

                                <small className="text-muted text-end ms-2">{poll.createdAt}</small>
                                {unrespondedPolls[poll.id] && (
                                    <Badge bg="danger" className="ms-2" pill>&#8226;</Badge>
                                )}
                            </Col>
                        </Row>
                        {isAdmin && (
                            <Row>
                                <Col md={12} className="d-flex ms-2 my-2">
                                    {/* Only show the edit button if there are no answers */}
                                    {pollsWithNoAnswers[poll.id] && (
                                        <Button variant="outline-primary" size="sm" onClick={() => onEditPoll(poll)} className="me-2">
                                            <FaEdit /> Uredi
                                        </Button>
                                    )}
                                    <Button variant="outline-danger" size="sm" onClick={() => handleDelete(poll.id)} className="me-2">
                                        <FaTrash /> Izbriši
                                    </Button>
                                    <Button variant="outline-warning" size="sm" onClick={() => handleEndPoll(poll.id)}>
                                        <FaBan /> Končaj
                                    </Button>
                                </Col>
                            </Row>
                        )}
                    </ListGroup.Item>
                ))}
            </ListGroup>
        </div>
    );
}

export default PollsList;