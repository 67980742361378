import React, { useState, useEffect } from 'react';
import { Navbar, Nav, NavDropdown, Container, Badge } from 'react-bootstrap';
import { Outlet, NavLink, useNavigate, useLocation } from 'react-router-dom';
import { doc, getDoc, getDocs, onSnapshot, query, where, orderBy, collection } from 'firebase/firestore';
import { auth, db } from "../../firebase/firebase-config";
import { signOut } from "firebase/auth";
import './Dashboard.css';

function Dashboard() {
  const [profileImage, setProfileImage] = useState("path_to_default_profile_image.png");
  const [hasUnreadChats, setHasUnreadChats] = useState(false);
  const [hasUnreadQnA, setHasUnreadQnA] = useState(false);
  const [hasUnrespondedPolls, setHasUnrespondedPolls] = useState(false);
  const [hasUnreadNotices, setHasUnreadNotices] = useState(false);
  const [activePath, setActivePath] = useState(''); // Track active path

  const navigate = useNavigate();
  const location = useLocation(); // Get the current location

  useEffect(() => {
    const fetchProfileImage = async () => {
      const docRef = doc(db, "users", auth.currentUser.uid);
      const docSnap = await getDoc(docRef);
      if (docSnap.exists() && docSnap.data().photoURL) {
        setProfileImage(docSnap.data().photoURL);
      }
    };

    fetchProfileImage();
  }, []);

  // When the location changes, update activePath
  useEffect(() => {
    setActivePath(location.pathname);
  }, [location.pathname]);

  const handleNavLinkClick = (path) => {
    if (activePath === path) {
      // If already on this path, reload the component
      navigate(0); // Forces a refresh of the current page
    } else {
      navigate(path); // Navigate to the new path
    }
  };

  useEffect(() => {
    const fetchUnreadChats = async () => {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      const lastReadTimestamps = userDocSnap.data().lastRead?.chat || {};

      const unsubscribes = [];

      const handleSnapshot = (unitId) => (querySnapshot) => {
        const hasUnread = querySnapshot.docs.some(doc => doc.data().uid !== auth.currentUser.uid);
        if (hasUnread) {
          setHasUnreadChats(true);
        }
      };

      Object.keys(lastReadTimestamps).forEach(unitId => {
        const q = query(
          collection(db, 'messages'),
          where('unit', '==', unitId),
          where('createdAt', '>', lastReadTimestamps[unitId])
        );

        const unsubscribe = onSnapshot(q, handleSnapshot(unitId));
        unsubscribes.push(unsubscribe);
      });

      return () => {
        unsubscribes.forEach(unsubscribe => unsubscribe());
      };
    };

    const fetchUnreadQnACount = async () => {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      const lastReadTimestamps = userDocSnap.data().lastRead?.qna || {};
    
      const q = query(collection(db, 'questions'));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let hasUnread = false;
    
        querySnapshot.forEach((doc) => {
          const questionId = doc.id;
          const createdAt = doc.data().createdAt.toMillis();
          const lastReadTimestamp = lastReadTimestamps[questionId]?.toMillis() || 0;
    
          // Check if the question's creation time is after the last read timestamp
          if (createdAt > lastReadTimestamp) {
            hasUnread = true;
          }
        });
    
        setHasUnreadQnA(hasUnread); // Update the state based on whether there are unread questions
      });
    
      return () => unsubscribe();
    };
    

    const fetchUnrespondedPollCount = async () => {
      const q = query(collection(db, 'polls'), where('expires', '>', new Date()));
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        let hasUnresponded = false;

        await Promise.all(querySnapshot.docs.map(async (doc) => {
          const pollId = doc.id;
          const answersRef = collection(db, 'polls', pollId, 'answers');
          const answersSnapshot = await getDocs(answersRef);
          const hasResponded = answersSnapshot.docs.some(answerDoc => answerDoc.data().userId === auth.currentUser.uid);

          if (!hasResponded) {
            hasUnresponded = true;
            setHasUnrespondedPolls(true);
          }
        }));

        if (!hasUnresponded) {
          setHasUnrespondedPolls(false);
        }
      });

      return () => unsubscribe();
    };

    const fetchUnreadNotices = async () => {
      const userDocRef = doc(db, 'users', auth.currentUser.uid);
      const userDocSnap = await getDoc(userDocRef);
      const lastReadTimestamps = userDocSnap.data().lastRead?.notices || {};

      const q = query(collection(db, 'notices'), orderBy('createdAt', 'desc'));
      const unsubscribe = onSnapshot(q, (querySnapshot) => {
        let hasUnread = false;

        querySnapshot.forEach((doc) => {
          const noticeId = doc.id;
          const createdAt = doc.data().createdAt;
          if (!lastReadTimestamps[noticeId] || lastReadTimestamps[noticeId].toMillis() < createdAt.toMillis()) {
            hasUnread = true;
            setHasUnreadNotices(true);
          }
        });

        if (!hasUnread) {
          setHasUnreadNotices(false);
        }
      });

      return () => unsubscribe();
    };

    fetchUnreadChats();
    fetchUnreadQnACount();
    fetchUnrespondedPollCount();
    fetchUnreadNotices();
  }, []);

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout Error:', error);
    }
  };

  return (
    <div className="dashboard-container">
      <Navbar collapseOnSelect expand="lg" variant="light" fixed="top" className="custom-navbar">
        <Container>
          <Navbar.Brand as={NavLink} to="/" className="brand">KSZS</Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link onClick={() => handleNavLinkClick('/chat')}>
                Klepetalnica {hasUnreadChats && <Badge bg="danger" pill>&#8226;</Badge>}
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick('/qna')}>
                Vprašanja {hasUnreadQnA && <Badge bg="danger" pill>&#8226;</Badge>}
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick('/polls')}>
                Ankete {hasUnrespondedPolls && <Badge bg="danger" pill>&#8226;</Badge>}
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick('/notices')}>
                Obvestila {hasUnreadNotices && <Badge bg="danger" pill>&#8226;</Badge>}
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick('/gallery')}>
                Galerija
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick('/documents')}>
                Dokumenti
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick('/sindikalist')}>
                Sindikalist
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick('/article')}>
                Članki
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick('/benefits')}>
                Ugodnosti
              </Nav.Link>
              <Nav.Link onClick={() => handleNavLinkClick('/contact')}>
                Kontakti
              </Nav.Link>
            </Nav>
            <Nav>
              <NavDropdown title={<img src={profileImage} alt="profile" className="profile-picture" />} id="collasible-nav-dropdown">
                <NavDropdown.Item as={NavLink} to="user-management">Upravljanje</NavDropdown.Item>
                <NavDropdown.Item as={NavLink} to="profile">Profil</NavDropdown.Item>
                <NavDropdown.Item onClick={handleLogout}>Odjava</NavDropdown.Item>
              </NavDropdown>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Outlet />

      <footer className="footer mt-auto py-3 bg-light">
        <Container>
          <span>© 2024 Konferenca sindikatov zavarovalništva Slovenije - All Rights Reserved</span>
        </Container>
      </footer>
    </div>
  );
}

export default Dashboard;
